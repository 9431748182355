
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  word-spacing: 10px;
  font-family: "Noto Sansf KR", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
  color: #716f6f;
  background-color: rgba(238, 255, 202, 1);
  text-align: center;
  padding: .6em 1.4em .5em .8em;
  margin: 0;
  
  border: none;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
}

select:hover {
  border-color: #7a4c4c;
}

select:focus {
  border-color: #575353;
  box-shadow: 0 0 1px 3px rgba(144, 142, 145, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;

}

select:disabled {
  opacity: 0.5;
}

label {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3;
  
  color: #444;

  margin-right: 0.5em;
}

form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  
}

body {
  margin: 0;
  
}